<template>
  <div class="calculator-chart" ref="chart"></div>
</template>

<script>
import loadGoogleCharts from './google-charts-loader'

let chartsLib = null
export default {
  props: {
    type: {
      type: String
    },
    data: {
      type: [Array, Object],
      default: () => []
    },
    options: {
      type: Object,
      default: () => ({})
    },
    version: {
      type: String,
      default: 'current'
    },
    settings: {
      type: Object,
      default: () => ({
        packages: ['corechart']
      })
    },
    events: {
      type: Object
    },
    createChart: {
      type: Function
    }
  },

  data() {
    return {
      chartObject: null,
      mutationObserver: null
    }
  },

  watch: {
    data: {
      deep: true,
      handler() {
        this.drawChart()
      }
    },
    options: {
      deep: true,
      handler() {
        this.drawChart()
      }
    },
    type() {
      this.createChartObject()
      this.drawChart()
    }
  },

  mounted() {
    loadGoogleCharts(this.version, this.settings).then(api => {
      chartsLib = api
      const chart = this.createChartObject()
      this.$emit('ready', chart, api)
      this.drawChart()
    })

    // HACK :) Chart Label Position
    // This will observe each new added text node
    // on chart and updates position of the text to be
    // on the left side of the node, except the first one.
    this.mutationObserver = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        mutation.addedNodes.forEach(node => {
          if (node.tagName === 'text' && node.getAttribute('text-anchor') == 'start') {
            const { width, labelPadding } = this.options.sankey.node
            const currentX = +node.getAttribute('x')
            const currentY = +node.getAttribute('y')
            // It should be the firs node labe
            if (currentX < 100) {
              node.setAttribute('y', currentY - 30)
            } else {
              const newX = currentX - width - labelPadding * 2
              node.setAttribute('text-anchor', 'end')
              node.setAttribute('x', newX)
            }
          }
        })
      })
    })

    this.mutationObserver.observe(this.$refs.chart, {
      childList: true,
      subtree: true
    })

    window.addEventListener('click', this.clickOutside)
    window.addEventListener('resize', this.drawChart)
  },

  beforeDestroy() {
    if (this.chartObject) {
      this.chartObject.clearChart()
    }
    if (this.mutationObserver) {
      this.mutationObserver.disconnect()
    }
    window.removeEventListener('click', this.clickOutside)
    window.removeEventListener('resize', this.drawChart)
  },

  methods: {
    clickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.drawChart()
      }
    },
    drawChart() {
      if (!chartsLib || !this.chartObject) return
      const data = this.getValidChartData()

      if (data) this.chartObject.draw(data, this.options)
    },

    getValidChartData() {
      if (this.data instanceof chartsLib.visualization.DataTable) return this.data
      if (this.data instanceof chartsLib.visualization.DataView) return this.data
      if (Array.isArray(this.data)) return chartsLib.visualization.arrayToDataTable(this.data)
      if (this.data !== null && typeof this.data === 'object') return new chartsLib.visualization.DataTable(this.data)
      return null
    },

    createChartObject() {
      const createChart = (el, google, type) => {
        if (!type) throw new Error('please, provide chart type property')
        return new google.visualization[type](el)
      }
      const fn = this.createChart || createChart
      this.chartObject = fn(this.$refs.chart, chartsLib, this.type)
      this.attachListeners()
      return this.chartObject
    },

    attachListeners() {
      if (!this.events) return
      Object.entries(this.events).forEach(([event, listener]) => {
        chartsLib.visualization.events.addListener(this.chartObject, event, listener)
      })
    }
  }
}
</script>

<style>
.calculator-chart {
  position: relative;
  overflow: hidden;
}

.calculator-chart rect {
  cursor: pointer;
}

.calculator-chart text {
  font-family: "soleil", sans-serif !important;
  letter-spacing: 0.05em;
  pointer-events: none !important;
}
</style>
