<template>
  <div>
    <div class="d-flex align-center justify-space-between">
      <p>{{ $t('calculator.overview') }}</p>
    </div>

    <div class="mt-2">
      <ka-google-chart
        :settings="{ packages: ['sankey'] }"
        type="Sankey"
        :data="chartData"
        :options="chartOptions"
      ></ka-google-chart>
    </div>
  </div>
</template>

<script>
import KaGoogleChart from './../components/ka-google-charts'
import renderChartTooltip from './renderChartTooltip'
import { DEFAULT_LOCALE } from '@web/constants/language'

export default {
  components: {
    KaGoogleChart
  },
  props: {
    salaryObject: Object
  },
  data() {
    const GREEN_COLOR = '#75CC9A'
    const RED_COLOR = '#FFA9A7'
    return {
      monthlyBase: true,
      chartOptions: {
        height: 400,
        tooltip: { isHtml: true },
        sankey: {
          node: {
            label: {
              fontSize: 12,
              bold: false,
              italic: false
            },
            interactivity: true, // Allows you to select nodes.
            labelPadding: 8, // Horizontal distance between the label and the node.
            nodePadding: 16, // Vertical distance between nodes.
            width: 12, // Thickness of the node.
            colors: [
              GREEN_COLOR,
              GREEN_COLOR,
              GREEN_COLOR,
              RED_COLOR,
              GREEN_COLOR,
              GREEN_COLOR,
              RED_COLOR,
              RED_COLOR,
              RED_COLOR,
              RED_COLOR,
              RED_COLOR,
              RED_COLOR,
              RED_COLOR,
              RED_COLOR,
              RED_COLOR,
              RED_COLOR
            ]
          },
          link: {
            colorMode: 'target'
          }
        }
      }
    }
  },
  computed: {
    locale: function () {
      return this.$store.getters.appLocale
    },
    bruttoSalary: function () {
      const multiplier = this.monthlyBase ? 1 : 12
      const { bruttoSalary } = this.salaryObject.employeeObject

      return +(bruttoSalary * multiplier).toFixed(2)
    },
    employerConscription: function () {
      const multiplier = this.monthlyBase ? 1 : 12
      const { employerConscription } = this.salaryObject.employeeObject

      const result = employerConscription.map(e => e.value).reduce((a, b) => a + b, 0)

      return +(result * multiplier).toFixed(2)
    },
    nettoSalary: function () {
      const divisor = this.monthlyBase ? 12 : 1
      const { nettoSalary } = this.salaryObject.employeeObject

      return +(nettoSalary / divisor).toFixed(2)
    },
    tax: function () {
      const divisor = this.monthlyBase ? 12 : 1
      const { tax } = this.salaryObject.employeeObject

      return +(tax / divisor).toFixed(2)
    },
    chartData: function () {
      if (!this.salaryObject) return null
      if (!window.google) return null
      var data = new window.google.visualization.DataTable()

      const allConscription = {}
      this.salaryObject.employeeObject.employeeConscription.forEach(entry => {
        const description = this.locale === DEFAULT_LOCALE ? entry.descriptionInSlovak : entry.description
        allConscription[description] = Math.abs(entry.value) || 0
      })
      this.salaryObject.employeeObject.employerConscription.forEach(entry => {
        const description = this.locale === DEFAULT_LOCALE ? entry.descriptionInSlovak : entry.description

        if (!allConscription[description]) {
          allConscription[description] = Math.abs(entry.value) || 0
        } else {
          allConscription[description] += Math.abs(entry.value) || 0
        }
      })
      data.addColumn('string', 'From')
      data.addColumn('string', 'To')
      data.addColumn('number', 'Weight')
      data.addColumn({
        type: 'string',
        role: 'tooltip',
        p: { html: true }
      })

      const rows = [
        [
          this.$t('calculator.price_of_labor'),
          this.$t('calculator.super_gross_salary'),
          this.bruttoSalary + this.employerConscription,
          renderChartTooltip(this.$tc('global.time.monthly', 2), this.$t('calculator.price_of_labor'), this.$t('calculator.super_gross_salary'), this.bruttoSalary + this.employerConscription)
        ],
        [this.$t('calculator.super_gross_salary'), this.$t('calculator.gross_salary'), this.bruttoSalary, renderChartTooltip(this.$tc('global.time.monthly', 2), this.$t('calculator.super_gross_salary'), this.$t('calculator.gross_salary'), this.bruttoSalary)],
        [
          this.$t('calculator.super_gross_salary'),
          this.$t('calculator.employer_levies'),
          this.employerConscription,
          renderChartTooltip(this.$tc('global.time.monthly', 2), this.$t('calculator.super_gross_salary'), this.$t('calculator.employer_levies'), this.employerConscription, false)
        ],
        [this.$t('calculator.gross_salary'), this.$t('calculator.gross_salary_after_tax'), this.nettoSalary, renderChartTooltip(this.$tc('global.time.monthly', 2), this.$t('calculator.gross_salary'), this.$t('calculator.gross_salary_after_tax'), this.nettoSalary)],
        [this.$t('calculator.gross_salary_after_tax'), this.$t('calculator.net_income'), this.nettoSalary, renderChartTooltip(this.$tc('global.time.monthly', 2), this.$t('calculator.gross_salary_after_tax'), this.$t('calculator.net_income'), this.nettoSalary)],
        ...this.salaryObject.employeeObject.employeeConscription.map(entry => {
          const description = this.locale === DEFAULT_LOCALE ? entry.descriptionInSlovak : entry.description

          return [
            this.$t('calculator.gross_salary'),
            description,
            this.formatConscription(entry.value),
            renderChartTooltip(this.$tc('global.time.monthly', 2), this.$t('calculator.gross_salary'), description, this.formatConscription(entry.value), false)
          ]
        }),
        ...this.salaryObject.employeeObject.employerConscription.map(entry => {
          const description = this.locale === DEFAULT_LOCALE ? entry.descriptionInSlovak : entry.description

          return [
            this.$t('calculator.employer_levies'),
            description,
            this.formatConscription(entry.value),
            renderChartTooltip(this.$tc('global.time.monthly', 2), this.$t('calculator.employer_levies'), description, this.formatConscription(entry.value), false)
          ]
        }),
        ...Object.keys(allConscription).map(key => {
          return [
            key,
            this.$t('calculator.deductions_together'),
            this.formatConscription(allConscription[key]),
            renderChartTooltip(this.$tc('global.time.monthly', 2), key, this.$t('calculator.deductions_together'), this.formatConscription(allConscription[key]), false)
          ]
        })
      ]
      if (this.salaryObject.employeeObject.tax > 0) {
        rows.push([this.$t('calculator.gross_salary'), this.$t('calculator.income_tax'), this.tax, renderChartTooltip(this.$tc('global.time.monthly', 2), this.$t('calculator.gross_salary'), this.$t('calculator.income_tax'), this.tax, false)])
        rows.push([this.$t('calculator.income_tax'), this.$t('calculator.deductions_together'), this.tax, renderChartTooltip(this.$tc('global.time.monthly', 2), this.$t('calculator.income_tax'), this.$t('calculator.deductions_together'), this.tax, false)])
      }
      data.addRows(rows)
      return data
    }
  },
  methods: {
    formatConscription: function (value) {
      const multiplier = this.monthlyBase ? 1 : 12
      const result = Math.abs(value) * multiplier
      return +result.toFixed(2)
    }
  }
}
</script>
