<template>
  <div>
    <div class="d-flex align-center justify-space-between">
      <p>{{ $t('calculator.overview') }}</p>
    </div>

    <div class="mt-2">
      <ka-google-chart
        :settings="{ packages: ['sankey'] }"
        type="Sankey"
        :data="chartData"
        :options="chartOptions"
      ></ka-google-chart>
    </div>
  </div>
</template>

<script>
import KaGoogleChart from './../components/ka-google-charts'
import renderChartTooltip from './renderChartTooltip'

export default {
  components: {
    KaGoogleChart
  },
  props: {
    salaryObject: Object
  },
  data() {
    const GREEN_COLOR = '#75CC9A'
    const RED_COLOR = '#FFA9A7'
    return {
      monthlyBase: true,
      chartOptions: {
        height: 300,
        tooltip: { isHtml: true },
        sankey: {
          node: {
            label: {
              fontSize: 12,
              bold: false,
              italic: false
            },
            interactivity: true, // Allows you to select nodes.
            labelPadding: 8, // Horizontal distance between the label and the node.
            nodePadding: 16, // Vertical distance between nodes.
            width: 12, // Thickness of the node.
            colors: [GREEN_COLOR, GREEN_COLOR, RED_COLOR, GREEN_COLOR, GREEN_COLOR, RED_COLOR, RED_COLOR]
          },
          link: {
            colorMode: 'target'
          }
        }
      }
    }
  },
  computed: {
    // Daň z príjmu
    tax: function () {
      const divisor = this.monthlyBase ? 12 : 1
      const { tax } = this.salaryObject.companyObject

      return +(tax / divisor).toFixed(2)
    },
    // Príjem
    income: function () {
      const divisor = this.monthlyBase ? 12 : 1
      const { grossIncome, tax } = this.salaryObject.companyObject

      return +((grossIncome - tax) / divisor).toFixed(2)
    },
    // Daň z dividend
    gainTax: function () {
      const divisor = this.monthlyBase ? 12 : 1
      const { gainTax } = this.salaryObject.companyObject

      return +(gainTax / divisor).toFixed(2)
    },
    // Čistý príjem
    nettoIncome: function () {
      const divisor = this.monthlyBase ? 12 : 1
      const { nettoIncome } = this.salaryObject.companyObject

      return +(nettoIncome / divisor).toFixed(2)
    },
    chartData: function () {
      if (!this.salaryObject) return null
      if (!window.google) return null
      var data = new window.google.visualization.DataTable()

      data.addColumn('string', 'From')
      data.addColumn('string', 'To')
      data.addColumn('number', 'Weight')
      data.addColumn({
        type: 'string',
        role: 'tooltip',
        p: { html: true }
      })

      const rows = [
        [this.$t('calculator.price_of_labor'), this.$t('calculator.average_invoice'), this.tax + this.income, renderChartTooltip(this.$tc('global.time.monthly', 2), this.$t('calculator.price_of_labor'), this.$t('calculator.average_invoice'), this.tax + this.income)],
        [this.$t('calculator.average_invoice'), this.$t('calculator.income_tax'), this.tax, renderChartTooltip(this.$tc('global.time.monthly', 2), this.$t('calculator.average_invoice'), this.$t('calculator.income_tax'), this.tax, false)],
        [this.$t('calculator.average_invoice'), this.$t('calculator.company_profit'), this.income, renderChartTooltip(this.$tc('global.time.monthly', 2), this.$t('calculator.average_invoice'), this.$t('calculator.company_profit'), this.income)],
        [this.$t('calculator.company_profit'), this.$t('calculator.net_income'), this.nettoIncome, renderChartTooltip(this.$tc('global.time.monthly', 2), this.$t('calculator.company_profit'), this.$t('calculator.net_income'), this.nettoIncome)],
        [this.$t('calculator.income_tax'), this.$t('calculator.taxes_and_levies'), this.tax, renderChartTooltip(this.$tc('global.time.monthly', 2), this.$t('calculator.income_tax'), this.$t('calculator.taxes_and_levies'), this.tax, false)],
        [this.$t('calculator.company_profit'), this.$t('calculator.dividend_tax'), this.gainTax, renderChartTooltip(this.$tc('global.time.monthly', 2), this.$t('calculator.company_profit'), this.$t('calculator.dividend_tax'), this.gainTax, false)],
        [this.$t('calculator.dividend_tax'), this.$t('calculator.taxes_and_levies'), this.gainTax, renderChartTooltip(this.$tc('global.time.monthly', 2), this.$t('calculator.dividend_tax'), this.$t('calculator.taxes_and_levies'), this.gainTax, false)]
      ]

      data.addRows(rows)
      return data
    }
  }
}
</script>
