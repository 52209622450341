<template>
  <div class="tw-rounded-lg tw-bg-white tw-py-8 tw-px-6 tw-flex tw-flex-wrap tw-items-center tw-justify-between">
    <div>
      <h3 class="tw-text-xl tw-mb-1 tw-font-bold title">{{ title }}</h3>

      <div class="tw-flex tw-items-center tw-gap-x-1.5">
        <span class="tw-text-xl">€</span>{{ rateFrom }} - {{ rateTo }} € / manday
      </div>
    </div>

    <k-button :to="to" color="secondary" class="btn">
      {{ $t('calculator.actions.get_hired') }} >
    </k-button>
  </div>
</template>

<script>
import { getRouteLocale } from '@web/constants/language'
import KButton from '@web/components/core/k-button.vue'

export default {
  name: 'calculator-job-offer',
  components: { KButton },
  props: {
    title: {
      type: String
    },
    rateFrom: {
      type: [String, Number]
    },

    rateTo: {
      type: [String, Number]
    },
    id: {
      type: String
    }
  },
  computed: {
    locale() {
      return getRouteLocale()
    },
    to() {
      return {
        name: 'project-detail',
        params: {
          id: this.id,
          locale: this.locale
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.btn {
  width: 250px;

  @media screen and (max-width: 767px) {
    width: 100%;
    margin-top: 16px;
  }
}
</style>
