import { PROFESSION_FILTERS } from '@web/constants/questionaire'

export const allProjectsBySearchString = (projects, searchString) => {
  return projects.filter(project => {
    const normalizedSearchString = searchString.toLowerCase()
    const normalizedProjectName = project.name.toLowerCase()

    if (normalizedProjectName.includes(normalizedSearchString)) {
      return true
    }

    if (!project.technologies || !project.technologies.length) {
      return false
    }

    const normalizedProjectTechnologies = project.technologies.map(item => item.toLowerCase())
    const foundTechnologies = normalizedProjectTechnologies.some(technology => technology.includes(normalizedSearchString))

    if (foundTechnologies) {
      return true
    }

    return false
  })
}

const getProfessionHighestRatedProject = (projects, profession) => {
  return projects.filter(item => item.type === profession).sort((a, b) => a.rateContractTo - b.rateContractTo)[0]
}

const lowerCased = word => word.toLowerCase()

export const getProjectsByProfessionAndWage = (projects, formResults) => {
  const { profession, data } = formResults

  if (!profession) {
    return []
  }

  const contractProjects = projects.filter(item => item.contract)

  if (!data) {
    const project = getProfessionHighestRatedProject(contractProjects, profession)

    return project ? [project] : []
  }

  const { wage, technologiesKnown } = data
  const professionFilters = PROFESSION_FILTERS[profession] || null

  if (!professionFilters) {
    const project = getProfessionHighestRatedProject(contractProjects, profession)

    return project ? [project] : []
  }

  const professionAndTechnologiesRelatedProjects = contractProjects
    .filter(item => item.type === profession && item.rateContractTo >= wage)
    .filter(item => item.technologies.some(technology => technologiesKnown.some(techKnown => lowerCased(techKnown).includes(lowerCased(technology)))))
    .sort((a, b) => a.rateContractTo - b.rateContractTo)
    .slice(0, 3)

  if (professionAndTechnologiesRelatedProjects.length) {
    return professionAndTechnologiesRelatedProjects
  }

  const professionRelatedProject = getProfessionHighestRatedProject(contractProjects, profession)

  if (professionRelatedProject) {
    return [professionRelatedProject]
  }

  let relatedProject = {}

  for (const profession of professionFilters.slice(1)) {
    const project = getProfessionHighestRatedProject(contractProjects, profession)

    if (project) {
      relatedProject = project

      break
    }
  }

  return [relatedProject]
}
