<template>
  <div>
    <div class="d-flex align-center justify-space-between">
      <p>{{ $t('calculator.overview') }}</p>
    </div>

    <div class="mt-2">
      <ka-google-chart
        :settings="{ packages: ['sankey'] }"
        type="Sankey"
        :data="chartData"
        :options="chartOptions"
      ></ka-google-chart>
    </div>
  </div>
</template>

<script>
import KaGoogleChart from './../components/ka-google-charts'
import renderChartTooltip from './renderChartTooltip'

export default {
  components: {
    KaGoogleChart
  },
  props: {
    salaryObject: Object
  },
  data() {
    return {
      monthlyBase: true
    }
  },
  computed: {
    chartOptions: function () {
      const GREEN_COLOR = '#75CC9A'
      const RED_COLOR = '#FFA9A7'
      let colors = []
      const nodesCount = this.chartData && this.chartData.fg ? this.chartData.fg.length + 1 : 0
      switch (nodesCount) {
        case 7:
          colors = [GREEN_COLOR, GREEN_COLOR, GREEN_COLOR, GREEN_COLOR, RED_COLOR, RED_COLOR, RED_COLOR]
          break
        case 8:
          colors = [
            GREEN_COLOR,
            GREEN_COLOR,
            GREEN_COLOR,
            GREEN_COLOR,
            this.flatRateExpenditure > 0 ? GREEN_COLOR : RED_COLOR,
            this.flatRateExpenditure < 0 ? GREEN_COLOR : RED_COLOR,
            RED_COLOR,
            RED_COLOR
          ]
          break
        case 10:
        default:
          colors = [GREEN_COLOR, GREEN_COLOR, GREEN_COLOR, GREEN_COLOR, GREEN_COLOR, RED_COLOR, RED_COLOR, RED_COLOR]
          break
      }
      return {
        height: 300,
        tooltip: { isHtml: true },
        sankey: {
          node: {
            label: {
              fontSize: 12,
              bold: false,
              italic: false
            },
            interactivity: true, // Allows you to select nodes.
            labelPadding: 8, // Horizontal distance between the label and the node.
            nodePadding: 16, // Vertical distance between nodes.
            width: 12, // Thickness of the node.
            colors: colors
          },
          link: {
            colorMode: 'target'
          }
        }
      }
    },
    // Paušálne výdavky
    flatRateExpenditure: function () {
      const divisor = this.monthlyBase ? 12 : 1
      const { grossIncome, grossTaxBase } = this.salaryObject.contractorObject

      return +((grossIncome - grossTaxBase) / divisor).toFixed(2)
    },
    // Zdravotné odvody
    medicalConscription: function () {
      const divisor = this.monthlyBase ? 12 : 1
      const { medicalConscription } = this.salaryObject.contractorObject

      return +(medicalConscription / divisor).toFixed(2)
    },
    // Hrubý daňový základ
    grossTaxBase: function () {
      const divisor = this.monthlyBase ? 12 : 1
      const { grossTaxBase } = this.salaryObject.contractorObject

      return +(grossTaxBase / divisor).toFixed(2)
    },
    // Daň z príjmu
    tax: function () {
      const divisor = this.monthlyBase ? 12 : 1
      const { tax } = this.salaryObject.contractorObject

      return +(tax / divisor).toFixed(2)
    },
    // Príjem
    incomeFromGrossTaxBase: function () {
      let result = this.grossTaxBase - (this.medicalConscription + this.tax)

      return +result.toFixed(2)
    },
    chartData: function () {
      if (!this.salaryObject) return null
      if (!window.google) return null
      var data = new window.google.visualization.DataTable()
      data.addColumn('string', 'From')
      data.addColumn('string', 'To')
      data.addColumn('number', 'Weight')
      data.addColumn({
        type: 'string',
        role: 'tooltip',
        p: { html: true }
      })

      const rows = [
        [
          this.$t('calculator.price_of_labor'),
          this.$t('calculator.average_invoice'),
          this.grossTaxBase + this.flatRateExpenditure,
          renderChartTooltip(this.$tc('global.time.monthly', 2), this.$t('calculator.price_of_labor'), this.$t('calculator.average_invoice'), this.grossTaxBase + this.flatRateExpenditure)
        ]
      ]

      if (this.incomeFromGrossTaxBase > 0) {
        rows.push([
          this.$t('calculator.gross_tax_base'),
          this.$t('calculator.net_income'),
          this.incomeFromGrossTaxBase,
          renderChartTooltip(this.$tc('global.time.monthly', 2), this.$t('calculator.gross_tax_base'), this.$t('calculator.net_income'), this.incomeFromGrossTaxBase)
        ])
      }

      if (this.flatRateExpenditure > 0) {
        rows.push([
          this.$t('calculator.average_invoice'),
          this.$t('calculator.flat_cost'),
          this.flatRateExpenditure,
          renderChartTooltip(this.$tc('global.time.monthly', 2), this.$t('calculator.average_invoice'), this.$t('calculator.flat_cost'), this.flatRateExpenditure)
        ])
        rows.push([
          this.$t('calculator.flat_cost'),
          this.$t('calculator.net_income'),
          this.flatRateExpenditure,
          renderChartTooltip(this.$tc('global.time.monthly', 2), this.$t('calculator.flat_cost'), this.$t('calculator.net_income'), this.flatRateExpenditure)
        ])
      }

      if (this.grossTaxBase > 0) {
        rows.push([
          this.$t('calculator.average_invoice'),
          this.$t('calculator.gross_tax_base'),
          this.grossTaxBase,
          renderChartTooltip(this.$tc('global.time.monthly', 2), this.$t('calculator.average_invoice'), this.$t('calculator.gross_tax_base'), this.grossTaxBase)
        ])
      }

      if (this.tax > 0) {
        rows.push([this.$t('calculator.gross_tax_base'), this.$t('calculator.income_tax'), this.tax, renderChartTooltip(this.$tc('global.time.monthly', 2), this.$t('calculator.gross_tax_base'), this.$t('calculator.income_tax'), this.tax, false)])
        rows.push([this.$t('calculator.income_tax'), this.$t('calculator.taxes_and_levies'), this.tax, renderChartTooltip(this.$tc('global.time.monthly', 2), this.$t('calculator.income_tax'), this.$t('calculator.taxes_and_levies'), this.tax, false)])
      }

      rows.push(
        [
          this.$t('calculator.gross_tax_base'),
          this.$t('calculator.health_insurance'),
          this.medicalConscription,
          renderChartTooltip(this.$tc('global.time.monthly', 2), this.$t('calculator.gross_tax_base'), this.$t('calculator.health_insurance'), this.medicalConscription, false)
        ],
        [
          this.$t('calculator.health_insurance'),
          this.$t('calculator.taxes_and_levies'),
          this.medicalConscription,
          renderChartTooltip(this.$tc('global.time.monthly', 2), this.$t('calculator.health_insurance'), this.$t('calculator.taxes_and_levies'), this.medicalConscription, false)
        ]
      )

      data.addRows(rows)
      return data
    }
  }
}
</script>
