/* eslint-disable no-console */
const constants = {
  minimumLivingWage: 268.88, // zivotne minimum
  editedTaxBaseConstant: 19.2, // konstanta na vypocet upraveneho zakladu dane
  basicTaxConstant: 0.19, // zakladna dan
  changedBasicTaxConstant: 0.15, // 2020
  millionaireTaxConstant: 0.25, // milionarska dan
  grossSalaryConstant: 1.362, // konstanta na prevod hrubej mzdy na superhrubu mzdu
  grossTaxBaseConstant: 0.866, // konstanta pre vypocet hrubeho zakladu dane

  employeeUntaxablePartOfTaxBaseMultiplier: 21, // konstanta pre vypocet nezdanitelnej casti
  employeeMillionaireUntaxablePartOfTaxBaseMultiplier: 44.2,
  employeeMillionaireUntaxablePartOfTaxBaseDivider: 4,
  employeeTaxModifierConstant: (1 / 12) * 176.8,

  employeeMedicalConscriptionPercentageConstant: 0.04, // konstanta pre vypocet zdravotneho poistenia plateneho zamestnancom
  employeeSickpayConscriptionPercentageConstant: 0.014, // konstanta pre vypocet nemocenskeho poistenia plateneho zamestnancom
  employeeDisabilityConscriptionPercentageConstant: 0.03, // konstanta pre vypocet invalidneho poistenia plateneho zamestnancom
  employeeRetirementConscriptionPercentageConstant: 0.04, // konstanta pre vypocet starobneho poistenia plateneho zamestnancom
  employeeUnemploymentConscriptionPercentageConstant: 0.01, // konstanta pre vypocet poistenia pre nezamestnanost plateneho zamestnancom

  employerMedicalConscriptionPercentageConstant: 0.11, // konstanta pre vypocet zdravotneho poistenia plateneho zamestnavatelom
  employerSickpayConscriptionPercentageConstant: 0.014, // konstanta pre vypocet nemocenskeho poistenia plateneho zamestnavatelom
  employerDisabilityConscriptionPercentageConstant: 0.03, // konstanta pre vypocet invalidneho poistenia plateneho zamestnavatelom
  employerRetirementConscriptionPercentageConstant: 0.14, // konstanta pre vypocet starobneho poistenia plateneho zamestnavatelom
  employerUnemploymentConscriptionPercentageConstant: 0.01, // konstanta pre vypocet poistenia pre nezamestnanost plateneho zamestnavatelom
  employerAccidentInsuranceConscriptionPercentageConstant: 0.008, // konstanta pre vypocet urazoveho poistenia  plateneho zamestnavatelom
  employerGaranteeSurplusConscriptionPercentageConstant: 0.0025, // konstanta pre vypocet odvodu do garancneho fondu plateneho zamestnavatelom
  employerReservesSurplusConscriptionPercentageConstant: 0.0475, // konstanta pre vypocet odvodu do rezervneho fondu plateneho zamestnavatelom

  contractorTaxSubstractableExpensesPercentageConstant: 0.6, // konstanta pre naklady zivnostnika, ktore si moze odpocitat pri vypoctu dane
  contractorTaxSubstractableExpensesConstant: 20000, // maximalne mozne naklady pre zivnostnika
  contractorBasicTaxLevelConstant: 39948,
  contractorGrossTaxBaseDistinguisherConstant: 49790,
  contractorMillionareTaxLevelConstant: 55269,
  contractorSmallTaxMultiplierConstant: 21,
  contractorBigTaxMultiplierConstant: 44.2,
  contractorMillionaireUntaxablePartOfTaxBaseDivider: 4,
  contractorMillionaireTaxMultiplierConstant: 176.8,
  contractorMedicalConscriptionMultiplierConstant: 1.486,
  contractorMedicalConscriptionPercentageConstant: 0.15,
  contractorMedicalConscriptionMinimumConstant: 97.80,
  contractorSocialConscriptionMultiplierConstant: 1.486,
  contractorSocialConscriptionPercentageConstant: 0.3315,
  contractorNumberOfWorkingDays: 218.4,
  contractorNumberOfWorkingMonths: 10.5,
  contractorTaxDistinguisherConstant: 60000,

  contractorMinimumSocialConscriptionConstant: 12 * 216.13,

  longTermContractorSocialConscriptionMaxValueConstant: 12 * 3025.93,

  companyTaxDistinguisherConstant: 60000,
  companySmallerTaxMultiplierConstant: 0.15,
  companyBiggerTaxMultiplierConstant: 0.21,
  companyGainTaxMultiplierConstant: 0.1,

  numberOfMonths: 12 // pocet mesiacov roku pre zamestnanca
}

const globalCounter = {
  basicTax: {
    condition() {
      return true
    },
    modifier(beforeTax) {
      return beforeTax * constants.basicTaxConstant
    },
    description: 'Returns the basic-taxed part of a sum'
  },
  improvedTax: {
    condition() {
      return true
    },
    modifier(beforeTax) {
      return beforeTax * constants.changedBasicTaxConstant
    },
    description: 'Returns the basic-taxed part of a sum - 2020'
  },
  millionaireTax: {
    condition() {
      return true
    },
    modifier(beforeTax) {
      return beforeTax * constants.millionaireTaxConstant
    },
    description: 'Returns the milionaire-taxed part of a sum'
  }
}
const employeeCounter = {
  grossSalary: {
    condition(salaryObject) {
      return salaryObject.type == 'employee'
    },
    modifier(salaryObject) {
      salaryObject.grossSalary = constants.numberOfMonths * salaryObject.bruttoSalary * constants.grossSalaryConstant
    },
    description: 'Computes the gross salary per year'
  },
  // computes gross tax base
  grossTaxBase: {
    condition(salaryObject) {
      return salaryObject.grossSalary !== undefined && salaryObject.type == 'employee'
    },
    modifier(salaryObject) {
      salaryObject.grossTaxBase = constants.numberOfMonths * salaryObject.bruttoSalary * constants.grossTaxBaseConstant
    },
    description: 'Computes gross tax base per year'
  },
  untaxablePartOfTaxBase: {
    condition(salaryObject) {
      return salaryObject.grossTaxBase !== undefined && salaryObject.type == 'employee'
    },
    modifier(salaryObject) {
      if (salaryObject.grossTaxBase <= 92.8 * constants.minimumLivingWage) {
        salaryObject.untaxablePartOfTaxBase = constants.minimumLivingWage * constants.employeeUntaxablePartOfTaxBaseMultiplier // zmena bola zapracovana
      } else {
        salaryObject.untaxablePartOfTaxBase = Math.max(
          0,
          constants.employeeMillionaireUntaxablePartOfTaxBaseMultiplier * constants.minimumLivingWage -
          salaryObject.grossTaxBase / constants.employeeMillionaireUntaxablePartOfTaxBaseDivider
        )
      }
    },
    description: 'Computes the untaxable part of tax base per year'
  },
  // computes edited tax base
  editedTaxBase: {
    condition(salaryObject) {
      return salaryObject.grossTaxBase !== undefined && salaryObject.untaxablePartOfTaxBase !== undefined && salaryObject.type == 'employee'
    },
    modifier(salaryObject) {
      salaryObject.editedTaxBase = salaryObject.grossTaxBase - salaryObject.untaxablePartOfTaxBase
    },
    description: 'Computes edited tax base per year'
  },
  tax: {
    condition(salaryObject) {
      return salaryObject.editedTaxBase !== undefined && salaryObject.type == 'employee'
    },
    modifier(salaryObject) {
      let comparingValue = constants.employeeTaxModifierConstant * constants.minimumLivingWage
      if (salaryObject.editedTaxBase / constants.numberOfMonths <= comparingValue) {
        salaryObject.tax = globalCounter.basicTax.modifier(salaryObject.editedTaxBase)
      } else {
        salaryObject.tax =
          constants.numberOfMonths *
          (globalCounter.millionaireTax.modifier(salaryObject.editedTaxBase / constants.numberOfMonths - comparingValue) +
            globalCounter.basicTax.modifier(comparingValue))
      }
    },
    description: 'Computes the tax paid by the employee towards the state per year'
  },
  // computes the netto salary of a regular employee
  nettoSalary: {
    condition(salaryObject) {
      return salaryObject.grossTaxBase !== undefined && salaryObject.tax !== undefined && salaryObject.type == 'employee'
    },
    modifier(salaryObject) {
      salaryObject.nettoSalary = salaryObject.grossTaxBase - salaryObject.tax
    },
    description: 'Computes the netto salary of a regular employee'
  },
  // computes conscriptions per month for the employee towards the state
  employeeConscriptions: {
    condition(salaryObject) {
      return salaryObject.type == 'employee'
    },
    modifier(salaryObject) {
      salaryObject.employeeConscription = [
        {
          description: 'Medical insurance',
          descriptionInSlovak: 'Zdravotné poistenie',
          value: salaryObject.bruttoSalary * constants.employeeMedicalConscriptionPercentageConstant,
          percentage: constants.employeeMedicalConscriptionPercentageConstant
        },
        {
          description: 'Sick-pay insurance',
          descriptionInSlovak: 'Nemocenské poistenie',
          value: salaryObject.bruttoSalary * constants.employeeSickpayConscriptionPercentageConstant,
          percentage: constants.employeeSickpayConscriptionPercentageConstant
        },
        {
          description: 'Retirement insurance',
          descriptionInSlovak: 'Starobné poistenie',
          value: salaryObject.bruttoSalary * constants.employeeRetirementConscriptionPercentageConstant,
          percentage: constants.employeeRetirementConscriptionPercentageConstant
        },
        {
          description: 'Disability insurance',
          descriptionInSlovak: 'Invalidné poistenie',
          value: salaryObject.bruttoSalary * constants.employeeDisabilityConscriptionPercentageConstant,
          percentage: constants.employeeDisabilityConscriptionPercentageConstant
        },
        {
          description: 'Unemployment insurance',
          descriptionInSlovak: 'Poistenie v nezamestnanosti',
          value: salaryObject.bruttoSalary * constants.employeeUnemploymentConscriptionPercentageConstant,
          percentage: constants.employeeUnemploymentConscriptionPercentageConstant
        }
      ]
    },
    description: 'Computes conscriptions for the employee towards the state'
  },
  // computes conscriptions per month for the employer towards the state
  employerConscriptions: {
    condition(salaryObject) {
      return salaryObject.type == 'employee'
    },
    modifier(salaryObject) {
      salaryObject.employerConscription = [
        {
          description: 'Medical insurance',
          descriptionInSlovak: 'Zdravotné poistenie',
          value: salaryObject.bruttoSalary * constants.employerMedicalConscriptionPercentageConstant,
          percentage: constants.employerMedicalConscriptionPercentageConstant
        },
        {
          description: 'Sick-pay insurance',
          descriptionInSlovak: 'Nemocenské poistenie',
          value: salaryObject.bruttoSalary * constants.employerSickpayConscriptionPercentageConstant,
          percentage: constants.employerSickpayConscriptionPercentageConstant
        },
        {
          description: 'Retirement insurance',
          descriptionInSlovak: 'Starobné poistenie',
          value: salaryObject.bruttoSalary * constants.employerRetirementConscriptionPercentageConstant,
          percentage: constants.employerRetirementConscriptionPercentageConstant
        },
        {
          description: 'Disability insurance',
          descriptionInSlovak: 'Invalidné poistenie',
          value: salaryObject.bruttoSalary * constants.employerDisabilityConscriptionPercentageConstant,
          percentage: constants.employerDisabilityConscriptionPercentageConstant
        },
        {
          description: 'Unemployment insurance',
          descriptionInSlovak: 'Poistenie v nezamestnanosti',
          value: salaryObject.bruttoSalary * constants.employerUnemploymentConscriptionPercentageConstant,
          percentage: constants.employerUnemploymentConscriptionPercentageConstant
        },
        {
          description: 'Garantee surplus',
          descriptionInSlovak: 'Garančný fond',
          value: salaryObject.bruttoSalary * constants.employerGaranteeSurplusConscriptionPercentageConstant,
          percentage: constants.employerGaranteeSurplusConscriptionPercentageConstant
        },
        {
          description: 'Reserves surplus',
          descriptionInSlovak: 'Rezervný fond',
          value: salaryObject.bruttoSalary * constants.employerReservesSurplusConscriptionPercentageConstant,
          percentage: constants.employerReservesSurplusConscriptionPercentageConstant
        },
        {
          description: 'Accident insurance',
          descriptionInSlovak: 'Úrazové poistenie',
          value: salaryObject.bruttoSalary * constants.employerAccidentInsuranceConscriptionPercentageConstant,
          percentage: constants.employerAccidentInsuranceConscriptionPercentageConstant
        }
      ]
    },
    description: 'Computes conscriptions fees for the employer towards the state'
  }
}
const contractorCounter = {
  grossIncome: {
    condition(salaryObject) {
      return salaryObject.type == 'contractor'
    },
    modifier(salaryObject) {
      salaryObject.grossIncome = salaryObject.bruttoSalary * constants.grossSalaryConstant * constants.numberOfMonths
    },
    description: 'Computes the gross income of a contractor per year'
  },
  grossTaxBase: {
    condition(salaryObject) {
      return salaryObject.type == 'contractor' && salaryObject.grossIncome !== undefined
    },
    modifier(salaryObject) {
      if (salaryObject.grossIncome <= constants.contractorGrossTaxBaseDistinguisherConstant) {
        salaryObject.grossTaxBase =
          salaryObject.grossIncome -
          Math.min(
            salaryObject.grossIncome * constants.contractorTaxSubstractableExpensesPercentageConstant,
            constants.contractorTaxSubstractableExpensesConstant
          )
      } else {
        salaryObject.grossTaxBase = salaryObject.grossIncome
      }
    },
    description: 'Computes the gross tax base of a contractor for the current year based on given gross income.'
  },
  untaxablePartOfTaxBase: {
    condition(salaryObject) {
      return salaryObject.type == 'contractor' && salaryObject.grossTaxBase !== undefined
    },
    modifier(salaryObject) {
      if (salaryObject.grossTaxBase <= 92.8 * constants.minimumLivingWage) {
        salaryObject.untaxablePartOfTaxBase = constants.contractorSmallTaxMultiplierConstant * constants.minimumLivingWage
      } else {
        salaryObject.untaxablePartOfTaxBase = Math.max(
          0,
          constants.contractorBigTaxMultiplierConstant * constants.minimumLivingWage -
          salaryObject.grossTaxBase / constants.contractorMillionaireUntaxablePartOfTaxBaseDivider
        )
      }
    },
    description: 'Computes the untaxable part of tax base for the contractor'
  },
  medicalConscription: {
    condition(salaryObject) {
      return salaryObject.type == 'contractor' && salaryObject.grossTaxBase !== undefined
    },
    modifier(salaryObject) {
      salaryObject.medicalConscription = Math.max(
        (salaryObject.grossTaxBase / constants.contractorMedicalConscriptionMultiplierConstant) *
        constants.contractorMedicalConscriptionPercentageConstant,
        constants.contractorMedicalConscriptionMinimumConstant * constants.numberOfMonths
      )
    },
    description: 'Computes medical conscription fees paid by the contractor towards the state'
  },
  tax: {
    condition(salaryObject) {
      return salaryObject.type == 'contractor' && salaryObject.grossTaxBase !== undefined && salaryObject.untaxablePartOfTaxBase !== undefined && salaryObject.grossIncome !== undefined
    },
    // TODO: what to do with salaryObject.untaxablePartOfTaxBase?
    // TODO: review this
    modifier(salaryObject) {
      if (salaryObject.grossIncome < constants.contractorTaxDistinguisherConstant) {
        salaryObject.tax = Math.max(globalCounter.improvedTax.modifier(salaryObject.grossTaxBase - salaryObject.untaxablePartOfTaxBase), 0)
      } else {
        salaryObject.tax =
          globalCounter.basicTax.modifier(constants.contractorMillionaireTaxMultiplierConstant * constants.minimumLivingWage) +
          globalCounter.millionaireTax.modifier(salaryObject.grossTaxBase - constants.contractorMillionaireTaxMultiplierConstant * constants.minimumLivingWage)
      }
    },
    description: 'Computes the tax paid by the contractor towards the state'
  },
  nettoIncome: {
    condition(salaryObject) {
      return (
        salaryObject.type == 'contractor' &&
        salaryObject.grossIncome !== undefined &&
        salaryObject.tax !== undefined &&
        salaryObject.medicalConscription !== undefined
      )
    },
    modifier(salaryObject) {
      salaryObject.nettoIncome = salaryObject.grossIncome - salaryObject.tax - salaryObject.medicalConscription
    },
    description: 'Computes the netto income per year based on gross income, taxes, conscriptions and other fees'
  }
}
const longTermContractorCounter = {
  grossIncome: {
    condition(salaryObject) {
      return salaryObject.type == 'long-term-contractor'
    },
    modifier(salaryObject) {
      salaryObject.grossIncome = salaryObject.bruttoSalary * constants.grossSalaryConstant * constants.numberOfMonths
    },
    description: 'Computes the gross income of a contractor per year'
  },
  grossTaxBase: {
    condition(salaryObject) {
      return salaryObject.type == 'long-term-contractor' && salaryObject.grossIncome !== undefined
    },
    modifier(salaryObject) {
      if (salaryObject.grossIncome <= constants.contractorGrossTaxBaseDistinguisherConstant) {
        salaryObject.grossTaxBase =
          salaryObject.grossIncome -
          Math.min(
            salaryObject.grossIncome * constants.contractorTaxSubstractableExpensesPercentageConstant,
            constants.contractorTaxSubstractableExpensesConstant
          )
      } else {
        salaryObject.grossTaxBase = salaryObject.grossIncome
      }
    },
    description: 'Computes the gross tax base of a contractor for the current year based on given gross income.'
  },
  untaxablePartOfTaxBase: {
    condition(salaryObject) {
      return salaryObject.type == 'long-term-contractor' && salaryObject.grossTaxBase !== undefined
    },
    modifier(salaryObject) {
      if (salaryObject.grossTaxBase <= 92.8 * constants.minimumLivingWage) {
        salaryObject.untaxablePartOfTaxBase = constants.contractorSmallTaxMultiplierConstant * constants.minimumLivingWage
      } else {
        salaryObject.untaxablePartOfTaxBase = Math.max(
          0,
          constants.contractorBigTaxMultiplierConstant * constants.minimumLivingWage -
          salaryObject.grossTaxBase / constants.contractorMillionaireUntaxablePartOfTaxBaseDivider
        )
      }
    },
    description: 'Computes the untaxable part of tax base for the contractor'
  },
  medicalConscription: {
    condition(salaryObject) {
      return salaryObject.type == 'long-term-contractor' && salaryObject.grossTaxBase !== undefined
    },
    modifier(salaryObject) {
      salaryObject.medicalConscription = Math.max(
        (salaryObject.grossTaxBase / constants.contractorMedicalConscriptionMultiplierConstant) *
        constants.contractorMedicalConscriptionPercentageConstant,
        constants.contractorMedicalConscriptionMinimumConstant * constants.numberOfMonths
      )
    },
    description: 'Computes medical conscription fees paid by the contractor towards the state'
  },
  socialConscription: {
    condition(salaryObject) {
      return salaryObject.type == 'long-term-contractor' && salaryObject.grossTaxBase !== undefined
    },
    modifier(salaryObject) {
      salaryObject.socialConscription = Math.min(
        Math.max(
          (salaryObject.grossTaxBase / constants.contractorSocialConscriptionMultiplierConstant) *
          constants.contractorSocialConscriptionPercentageConstant,
          constants.contractorMinimumSocialConscriptionConstant
        ),
        constants.longTermContractorSocialConscriptionMaxValueConstant
      )
    },

    description: 'Computes the social conscription fees paid by the contractor towards the state'
  },
  tax: {
    condition(salaryObject) {
      return (
        salaryObject.type == 'long-term-contractor' &&
        salaryObject.grossTaxBase !== undefined &&
        salaryObject.untaxablePartOfTaxBase !== undefined &&
        salaryObject.socialConscription !== undefined &&
        salaryObject.medicalConscription !== undefined
      )
    },
    modifier(salaryObject) {
      salaryObject.grossTaxBase -= salaryObject.medicalConscription + salaryObject.socialConscription

      // TODO: what to do with salaryObject.untaxablePartOfTaxBase?
      // TODO: review this
      if (salaryObject.grossIncome < constants.contractorTaxDistinguisherConstant) {
        salaryObject.tax = Math.max(globalCounter.improvedTax.modifier(salaryObject.grossTaxBase - salaryObject.untaxablePartOfTaxBase), 0)
      } else {
        salaryObject.tax =
          globalCounter.basicTax.modifier(constants.contractorMillionaireTaxMultiplierConstant * constants.minimumLivingWage) +
          globalCounter.millionaireTax.modifier(salaryObject.grossTaxBase - constants.contractorMillionaireTaxMultiplierConstant * constants.minimumLivingWage)
      }

      salaryObject.grossTaxBase += salaryObject.medicalConscription + salaryObject.socialConscription
    },
    description: 'Computes the tax paid by the contractor towards the state'
  },
  nettoIncome: {
    condition(salaryObject) {
      return (
        salaryObject.type == 'long-term-contractor' &&
        salaryObject.grossIncome !== undefined &&
        salaryObject.tax !== undefined &&
        salaryObject.medicalConscription !== undefined &&
        salaryObject.socialConscription !== undefined
      )
    },
    modifier(salaryObject) {
      salaryObject.nettoIncome = salaryObject.grossIncome - salaryObject.tax - salaryObject.medicalConscription - salaryObject.socialConscription
    },
    description: 'Computes the netto income per year based on gross income, taxes, conscriptions and other fees'
  }
}
const companyCounter = {
  grossIncome: {
    condition(salaryObject) {
      return salaryObject.type == 'company'
    },
    modifier(salaryObject) {
      salaryObject.grossIncome = salaryObject.bruttoSalary * constants.grossSalaryConstant * 12
    },
    description: 'Computes the gross income of a company per year'
  },

  tax: {
    condition(salaryObject) {
      return salaryObject.type == 'company' && salaryObject.grossIncome !== undefined
    },
    modifier(salaryObject) {
      if (salaryObject.grossIncome < constants.companyTaxDistinguisherConstant) {
        salaryObject.tax = salaryObject.grossIncome * constants.companySmallerTaxMultiplierConstant
      } else {
        salaryObject.tax = salaryObject.grossIncome * constants.companyBiggerTaxMultiplierConstant
      }
    },
    description: 'Computes the regular tax of a company paid towards the state'
  },
  gainTax: {
    condition(salaryObject) {
      return salaryObject.type == 'company' && salaryObject.grossIncome !== undefined && salaryObject.tax !== undefined
    },
    modifier(salaryObject) {
      salaryObject.gainTax = (salaryObject.grossIncome - salaryObject.tax) * constants.companyGainTaxMultiplierConstant
    },
    description: 'Computes the gain tax of a company paid towards the state'
  },
  nettoIncome: {
    condition(salaryObject) {
      return (
        salaryObject.type == 'company' && salaryObject.grossIncome !== undefined && salaryObject.tax !== undefined && salaryObject.gainTax !== undefined
      )
    },
    modifier(salaryObject) {
      salaryObject.nettoIncome = salaryObject.grossIncome - salaryObject.tax - salaryObject.gainTax
    },
    description: 'Computes the netto income of a company based on the gross income per year'
  }
}

const wageCalculator = {
  constants,
  globalCounter,
  employeeCounter,
  contractorCounter,
  longTermContractorCounter,
  companyCounter
}

// export default wageCalculator;
module.exports = wageCalculator
