<template>
  <section class="tw-bg-white tw-px-5 tw-py-10 sm:tw-rounded-2xl md:tw-p-10 lg:tw-p-14">
    <div class="tw-mb-10 lg:tw-mb-12">
      <h3 class="tw-text-4xl tw-font-bold tw-leading-tight">
        <slot name="title">
          {{ title }}
        </slot>
      </h3>
      <p class="tw-text-lg tw-leading-relaxed tw-mt-6 tw-max-w-md">
        <slot name="subtitle">
          {{ subtitle }}
        </slot>
      </p>
    </div>

    <div class="tw-grid md:tw-grid-cols-3 tw-gap-4">
      <div class="tw-border-gray-100 tw-rounded-lg lg:tw-p-6 lg:tw-border-2" :class="leftClass">
        <p class="tw-mb-2 tw-text-sm tw-text-gray-800">
          <slot name="leftTitle">
            {{ leftTitle }}
          </slot>
        </p>
        <p class="tw-font-bold tw-text-3.5xl tw-leading-relaxed">
          <slot name="leftSubtitle">
            {{ leftSubtitle }}
          </slot>
        </p>

        <slot name="leftAddition" />
      </div>

      <div class="tw-border-gray-100 tw-rounded-lg lg:tw-p-6  lg:tw-border-2">
        <p class="tw-mb-2 tw-text-sm tw-text-gray-800">
          <slot name="middleTitle">
            {{ middleTitle }}
          </slot>
        </p>
        <p class="tw-font-bold tw-text-3.5xl tw-leading-relaxed">
          <slot name="middleSubtitle">
            {{ middleSubtitle }}
          </slot>
        </p>

        <slot name="middleAddition" />
      </div>

      <div class="tw-border-gray-100 tw-rounded-lg lg:tw-p-6  lg:tw-border-2">
        <p class="tw-mb-2 tw-text-sm tw-text-gray-800">
          <slot name="rightTitle">
            {{ rightTitle }}
          </slot>
        </p>
        <p class="tw-font-bold tw-text-3.5xl tw-leading-relaxed">
          <slot name="rightSubtitle">
            {{ rightSubtitle }}
          </slot>
        </p>

        <slot name="rightAddition" />
      </div>
    </div>

    <slot />
  </section>
</template>

<script>
export default {
  name: 'calculator-section',
  props: ['title', 'subtitle', 'leftTitle', 'leftSubtitle', 'middleTitle', 'middleSubtitle', 'rightTitle', 'rightSubtitle', 'leftClass']
}
</script>

<style scoped>

</style>
