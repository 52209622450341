<template>
  <div class="table-container">
    <div class="table-wrapper">
      <table>
        <thead>
        <tr>
          <th></th>
          <th>%</th>
          <th>{{ $tc('global.time.monthly', 1) }}</th>
          <th>{{ $t('global.time.annual') }}</th>
        </tr>
        </thead>

        <tbody>
        <tr
          v-for="(entry, index) in conscription"
          :key="index"
        >
          <td>{{ appLocale === defaultAppLocale ? entry.descriptionInSlovak : entry.description }}</td>
          <td>{{ entry.percentage | percentage | calculatorNumber }}%</td>
          <td>{{ entry.value | calculatorNumber }}€</td>
          <td>{{ entry.value | yearly | calculatorNumber }}€</td>
        </tr>
        <tr v-if="isEmployee">
          <td>{{ $t('calculator.income_tax') }}</td>
          <td>-</td>
          <td>{{ tax | monthly | calculatorNumber }}€</td>
          <td>{{ tax | calculatorNumber }}€</td>
        </tr>
        <tr>
          <td>{{ $t('calculator.deductions_together') }}</td>
          <td>
            <span v-if="isEmployee">{{ $t('calculator.taxes') }} +</span>

            <template v-if="conscription.length">
              {{ conscriptionPercentage | percentage | calculatorNumber }}
            </template>

            <template v-else>
              {{ 0 | percentage | calculatorNumber }}
            </template>
            %
          </td>
          <td>
            <template v-if="conscription.length">
              {{ employeeMonthTax | calculatorNumber }}
            </template>

            <template v-else>
              {{ 0 | calculatorNumber }}
            </template>
            €
          </td>
          <td>
            <template v-if="conscription.length">
              {{ employeeYearTax | calculatorNumber }}
            </template>

            <template v-else>
              {{ 0 | calculatorNumber }}
            </template>
            €
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import formatNumber from '@web/calculator/formatNumber'
import { DEFAULT_LOCALE } from '@web/constants/language'

export default {
  props: {
    conscription: {
      type: Array,
      default: () => []
    },
    tax: {
      type: Number,
      default: 0
    },
    isEmployee: {
      type: Boolean,
      default: false
    },
    appLocale: {
      type: String,
      default: DEFAULT_LOCALE
    }
  },
  data() {
    return {
      defaultAppLocale: DEFAULT_LOCALE
    }
  },
  filters: {
    calculatorNumber: formatNumber,
    monthly: function (floatNumber) {
      return floatNumber / 12
    },
    yearly: function (floatNumber) {
      return floatNumber * 12
    },
    percentage: function (floatNumber) {
      return floatNumber * 100
    }
  },
  computed: {
    conscriptionPercentage() {
      return this.conscription.map(e => e.percentage).reduce((a, b) => a + b, 0)
    },
    conscriptionSum() {
      return this.conscription.map(e => e.value).reduce((a, b) => a + b, 0)
    },
    employeeMonthTax() {
      return this.isEmployee ? this.conscriptionSum + this.tax / 12 : this.conscriptionSum
    },
    employeeYearTax() {
      return this.isEmployee ? this.conscriptionSum * 12 + this.tax : this.conscriptionSum * 12
    }
  }
}
</script>

<style lang="scss" scoped>
.table-container {
  .table-wrapper {
    width: 100%;

    @include -md {
      overflow-x: scroll;
    }
  }

  table {
    width: 100%;
    min-width: 32.5rem;
    border-collapse: collapse;

    thead {
      tr {
        border-bottom: 0.063rem solid $koderia-whitesmoke;

        th {
          @include padding-bottom-multiplier(1);

          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            text-align: right;
          }
        }
      }
    }

    tbody {
      tr {
        border-bottom: 0.063rem solid $koderia-whitesmoke;

        &:last-child {
          border-bottom: none;

          td {
            @include padding-top-multiplier(1);
            font-size: map-get(map-get($text, title), size);
            line-height: map-get(map-get($text, title), line-height);
            font-weight: map-get(map-get($text, title), font-weight);
          }
        }

        td {
          @include padding-top-multiplier(1);
          @include padding-bottom-multiplier(1);

          font-size: map-get(map-get($text, small), size);
          line-height: map-get(map-get($text, small), line-height);

          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            text-align: right;
          }
        }
      }
    }
  }
}
</style>
