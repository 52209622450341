import { render, staticRenderFns } from "./calculator-job-offer.vue?vue&type=template&id=03ea6077&scoped=true&"
import script from "./calculator-job-offer.vue?vue&type=script&lang=js&"
export * from "./calculator-job-offer.vue?vue&type=script&lang=js&"
import style0 from "./calculator-job-offer.vue?vue&type=style&index=0&id=03ea6077&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03ea6077",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KButton from '@web/components/core/k-button'
installComponents(component, {KButton})
